import { OFFSET, PERMISSIONS } from "../../../utils/Constants";

export const USER_DATA_INITIAL = {
  list: [],
  loading: true,
  page: 1,
  offset: OFFSET,
  search: "",
  total: 0,
  selected: null,
  profile: null,
  sort: null,
};

export const USER_TABLE_HEADER = [
  { type: "date", name: "Created At", value: "createdAt" },
  {
    type: "string",
    name: "Name",
    value: "name",
    sort: true,
    sortType: "",
  },
  { type: "component", name: "Contact Info", value: "contactInfo" },
  { type: "string", name: "Organisation", value: "organizationName" },
  {
    type: "string",
    name: "Created By",
    value: "createdByRole",
    sort: true,
    sortType: "",
  },
  { type: "component", name: "Bank Account", value: "bankAccount" },
  {
    type: "string",
    name: "Balance",
    value: "balance",
    sort: true,
    sortType: "",
  },
  { type: "component", name: "Bank Reset", value: "bankReset" },
  { type: "string", name: "2FA", value: "2fa" },
  {
    type: "toggle",
    name: "EDD Status",
    value: "eStatus",
    sort: true,
    sortType: "",
  },
  { type: "string", name: "Status", value: "status" },
  {
    type: "menu",
    name: "Action",
    value: "action",
  },
];

export const DEFAULT_ACTIONS = [
  { icon: "", name: "Reset Password", value: "reset-password" },
  { icon: "", name: "Edit", value: "edit" },
  {
    icon: "",
    name: "Suspend",
    value: "suspend",
  },
];

export const RECOVER_ACTIONS = [
  { icon: "", name: "Reset Password", value: "reset-password" },
  { icon: "", name: "Edit", value: "edit" },
  {
    icon: "",
    name: "Recover",
    value: "recover",
  },
];

export const RESET_SUSPEND_ACTIONS = [
  { icon: "", name: "Reset Password", value: "reset-password" },
  { icon: "", name: "Edit", value: "edit" },
  {
    icon: "",
    name: "Suspend",
    value: "suspend",
  },
];
export const RESET_RECOVER_ACTIONS = [
  { icon: "", name: "Reset Password", value: "reset-password" },
  { icon: "", name: "Edit", value: "edit" },
  {
    icon: "",
    name: "Recover",
    value: "recover",
  },
];

export const VERIFY_SUSPEND_ACTIONS = [
  { icon: "", name: "Resend Verification Mail", value: "verify" },
  { icon: "", name: "Edit", value: "edit" },
  {
    icon: "",
    name: "Suspend",
    value: "suspend",
  },
];

export const VERIFY_RECOVER_ACTIONS = [
  { icon: "", name: "Resend Verification Mail", value: "verify" },
  { icon: "", name: "Edit", value: "edit" },
  {
    icon: "",
    name: "Recover",
    value: "recover",
  },
];

export const MENU_OPTIONS = [
  PERMISSIONS.RESEND_RESET_PASSWORD_EMAIL_USER,
  PERMISSIONS.RESEND_SET_PASSWORD_EMAIL_USER,
  PERMISSIONS.UNSUSPEND_USER,
  PERMISSIONS.SUSPEND_USER,
  PERMISSIONS.EDIT_USER,
];

export const MENU_OPTIONS_OBJ = {
  ACTION: "action",
  EDIT: "edit",
  SUSPEND: "suspend",
  RECOVER: "recover",
  ESTATUS: "eStatus",
  BANK_RESET: "bankReset",
  BANK_ACCOUNT: "bankAccount",
  FIELD: "field",
  RESET_PASSWORD: "reset-password",
  VERIFY: "verify",
};

export const KYC_STATUS = {
  NOT_VERIFIED: "Not Verified",
  IN_PROGRESS: "In Progress",
  APPROVED: "Approved",
  REJECTED: "Rejected",
};

export const USERS = "Users";
export const DELETE = "Delete";
export const RECOVER = "Recover";
export const SUSPEND = "Suspend";
export const VERIFY = "Verify";
export const VERIFY_EMAIL = "Verify Email";
export const RESEND_VERIFY_EMAIL = "Resend Verification Email";
export const RESET_PASSWORD = "Reset Password";
export const SEND = "Send";
export const EDIT = "Edit";

export const EEDD_STATUS = "EDD Status";
export const RESET_BANK = "Confirm Bank Reset";

export const EEDD_STATUS_DESC = "Are you sure you want to verify EDD status?";
export const RESET_BANK_DESC =
  "Click reset to trigger a bank account reset for this customer account";

export const VERIFY_EMAIL_HEADING =
  "Are you sure you want to send verification email to the user?";

export const DELETE_USER_HEADING = "Are you sure you want to delete this user?";

export const RECOVER_USER_HEADING =
  "Are you sure you want to recover this user?";

export const SUSPEND_USER_HEADING =
  "Are you sure you want to suspend this user?";
export const RESET_PASSWORD_HEADING =
  "Are you sure you want to send reset password link to user's mail?";

export const USER_ID = "userId";
export const SUSPENDED = "Suspended";

export const BANK_TITLE = {
  BANK_NAME: "Bank Name",
  BSB: "BSB",
  ACC_NO: "Acc No",
};

export const TRANSACTION_STATUS = {
  UN_APPROVED: "UnApproved",
  CANCELLED: "Cancelled",
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected",
};

export const RESET_2FA = "Confirm 2FA Reset";
export const RESET_2FA_DESC =
  "Are you sure you want to reset the 2FA for this customer?";
