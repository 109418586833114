import * as z from "zod";
import {
  UpdateUserProfile,
  VerifyCurrentPassword,
} from "../../../services/user";
import { error, success } from "../../shared/Alert";
import {
  CONTACT_NUMBER,
  DOB,
  EMAIL,
  FIRST_NAME,
  KYC_PENDING,
  LAST_NAME,
  NAME_CHANGE_WARNING_HEADING,
  NAME_CHANGE_WARNING_SUBHEADING,
  NOT_VERIFIED,
  PENDING,
  PROFILE_UPDATE_CONFIRM_HEADING,
  PROFILE_UPDATE_CONFIRM_SUBHEADING,
  REJECTED,
  VERIFIED,
} from "./constants";
import { GetSumSubToken, UpdateKYCLevel } from "../../../services/user";
import { ClearSession } from "../../../config/axios";
import {
  GetReset2FA,
  VerifyReset2FA,
  ChangeUserPassword,
  VerifyChangeEmail,
  ChangeUserEmail,
} from "../../../services/user";

const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
);

export const identificationSchema = z.object({
  firstName: z
    .string()
    .trim()
    .min(1, { message: "First name is required" })
    .regex(/^[A-Za-z\s]+$/, {
      message: "First name must contain only alphabetic characters",
    })
    .max(30, { message: "Maximum 30 characters" }),
  lastName: z
    .string()
    .trim()
    .min(1, { message: "Last name is required" })
    .regex(/^[A-Za-z\s]+$/, {
      message: "Last name must contain only alphabetic characters",
    })
    .max(30, { message: "Maximum 30 characters" }),
  email: z
    .string()
    .min(1, { message: "Email is required" })
    .email({ message: "Please enter a valid email" }),
  dob: z
    .string()
    .min(1, { message: "Date of birth is required" })
    .refine(
      (date) => {
        // Validate if the user is at least 18 years old
        const dob = new Date(date);
        const minDate = new Date();
        minDate.setFullYear(minDate.getFullYear() - 18);

        return dob < minDate;
      },
      {
        message: "Minimum age requirement of 18 years",
      }
    )
    .refine(
      (date) => {
        const dob = new Date(date);
        return dob.getFullYear() > 1900;
      },
      {
        message: "Maximum date range greater than 1900",
      }
    ),
  contactNumber: z
    .string()
    .max(13, { message: "Contact No must be at most 13 characters" })
    .optional()
    .refine((value) => !value || (value.length > 0 && phoneRegex.test(value)), {
      message: "Please enter a valid contact number",
    }),
});

export const initialAccountSettingValues = {
  firstName: "",
  lastName: "",
  email: "",
  dob: "",
  contactNumber: "",
};

export const getUpdatesValues = (data) => {
  const { firstName, lastName, contactNumber, email, dob } = data;

  return { firstName, lastName, contactNumber, email, dob };
};

export const updateUserProfileHandler = async (
  formData,
  setIsSubmitting,
  reset,
  handeUpdateUserProfile
) => {
  setIsSubmitting(true);

  const resp = await UpdateUserProfile(formData);

  if (resp.status === 200 && resp.data) {
    success(resp.message);
    reset(getUpdatesValues(resp.data));
    handeUpdateUserProfile(resp.data);
  } else {
    error(resp.message);
  }

  setIsSubmitting(false);
};

export const defaultUserProfileValues = {
  loading: false,
  data: {},
};

export const prefillUserValues = (userProfile, setValue) => {
  if (userProfile?.data?.firstName)
    setValue(FIRST_NAME, userProfile?.data?.firstName);

  if (userProfile?.data?.lastName)
    setValue(LAST_NAME, userProfile?.data?.lastName);

  if (userProfile?.data?.email) setValue(EMAIL, userProfile?.data?.email);

  if (userProfile?.data?.contactNumber)
    setValue(CONTACT_NUMBER, userProfile?.data?.contactNumber);

  if (userProfile?.data?.dob) setValue(DOB, userProfile?.data?.dob);
};
export const getSumSubToken = async (id, setSumSubToken, setLoading) => {
  const resp = await GetSumSubToken(id);
  if (resp.status === 200 && resp.data && resp.data.token) {
    setSumSubToken(resp.data.token);
    setLoading(false);
  } else {
    setLoading(false);
  }
};

export const updateKYCLevel = async (
  id,
  level,
  handleRefreshUserProfile,
  setIsSumSubVerificationOpen,
  isEddModal
) => {
  const resp = await UpdateKYCLevel(id, level, isEddModal);
  if (resp.status === 200) {
    handleRefreshUserProfile();
    setIsSumSubVerificationOpen(false);
  }
};

export const changeEmailSchema = z.object({
  email: z
    .string()
    .min(1, { message: "Email is required" })
    .email({ message: "Please enter a valid email" }),
});

export const initialChangeEmailValues = {
  email: "",
};

export const verifyCurrentPasswordSchema = z.object({
  currentPassword: z
    .string()
    .min(1, { message: "Current password is required" }),
});

export const initialVerifyCurrentPasswordValues = {
  currentPassword: "",
};

export const changeUserEmailHandler = async (
  formData,
  onCloseModal,
  emailChangeToken
) => {
  const resp = await ChangeUserEmail({
    ...formData,
    emailToken: emailChangeToken.token,
  });

  if (resp.status === 200) {
    success(resp.message);
    onCloseModal();
  } else {
    error(resp.message);
  }
};

export const defaultEmailValue = {
  status: PENDING,
};

export const initialChangePasswordValues = {
  currentPassword: "",
  password: "",
  confirmPassword: "",
};

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\-!_@#$%^&*])(?=.{8,})/;

export const changePasswordSchema = z
  .object({
    currentPassword: z
      .string()
      .min(1, { message: "Please enter current password" }),
    password: z.string().refine((value) => passwordRegex.test(value), {
      message:
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, and One Special Character",
    }),
    confirmPassword: z.string().refine((value) => passwordRegex.test(value), {
      message:
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, and One Special Character",
    }),
  })
  .superRefine((value, ctx) => {
    if (value.currentPassword === value.password) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "New password should be different from old password",
        path: ["password"],
      });
    }

    if (value.password !== value.confirmPassword) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Passwords do not match",
        path: ["confirmPassword"],
      });
    }
  });

export const changeUserPasswordHandler = async (formData, onCloseModal) => {
  const resp = await ChangeUserPassword(formData);

  if (resp.status === 200) {
    success(resp.message);
    onCloseModal();
  } else {
    error(resp.message);
  }
};

export const defaultTwoFa = {
  loading: false,
  enabled: false,
  dataUrl: "",
  secret: "",
};

export const initialTwoFaValues = {
  verificationCode: "",
  id: "",
};

export const towFaSchema = z.object({
  verificationCode: z
    .string({ required_error: "Verification code is required" })
    .min(6, { message: "Please input the 6 digits 2FA code" })
    .max(6, { message: "Please input the 6 digits 2FA code" }),
  id: z.string({
    required_error: "Id is required",
  }),
});

export const getResetTwoFA = async (setTwoFa) => {
  setTwoFa((prev) => {
    return { ...prev, loading: true };
  });

  const resp = await GetReset2FA();

  if (resp.status === 200) {
    setTwoFa((prev) => {
      return {
        ...prev,
        enabled: false,
        loading: false,
        dataUrl: resp.data,
        secret: resp.google2faSecret,
      };
    });
  } else {
    setTwoFa((prev) => {
      return { ...prev, loading: false };
    });
    error(resp.message);
  }
};

export const verifyResetTwoFA = async (data, onCloseModal) => {
  const resp = await VerifyReset2FA(data);
  if (resp.status === 200) {
    success(resp.message);
    onCloseModal();
  } else {
    error(resp.message);
  }
};

export const defaultIsOpenValues = {
  passwordUpdate: false,
  emailUpdate: false,
  updateDetail: false,
  verifyPass: false,
};

export const defaultIs2FAOpenValues = {
  disableModal: false,
  resetModal: false,
};

export const verifyCurrentPasswordHandler = async (
  formData,
  onCloseModal,
  setIsOpen,
  setEmailChangeToken
) => {
  const resp = await VerifyCurrentPassword(formData);

  if (resp.status === 200 && resp.emailToken) {
    setEmailChangeToken((prev) => ({ ...prev, token: resp.emailToken }));

    onCloseModal();

    setIsOpen((prev) => ({
      ...prev,
      verifyPass: false,
      emailUpdate: true,
    }));
  } else {
    error(resp.message);
  }
};

export const defaultEmailChangeValues = {
  token: "",
};

export const cancelUpdateHandler = (userProfile) => {
  const { firstName, lastName, contactNumber, email, dob } = userProfile?.data;

  return { firstName, lastName, contactNumber, email, dob };
};

export const getKYCStatus = (level) => {
  const getKYCInfo = (level) => {
    switch (level) {
      case 0:
        return { badge: "badge-danger", status: REJECTED };
      case 1:
        return { badge: "badge-danger", status: NOT_VERIFIED };
      case 2:
        return { badge: "badge-warning", status: KYC_PENDING };
      case 3:
        return { badge: "badge-primary", status: VERIFIED };
      default:
        return "";
    }
  };

  const KYCInfo = getKYCInfo(level);

  return (
    <p className={`badge badge-rounded mx-2 ${KYCInfo.badge}`}>
      {KYCInfo.status}
    </p>
  );
};

export const getUpdateAlertText = (data, userProfile) => {
  const isNameChangedHandler = (data) => {
    return (
      data?.firstName !== userProfile?.data?.firstName ||
      data?.lastName !== userProfile?.data?.lastName
    );
  };

  const isNameChanged = isNameChangedHandler(data);

  return {
    heading:
      isNameChanged &&
      userProfile.data.level !== 1 &&
      userProfile.data.level !== 2
        ? NAME_CHANGE_WARNING_HEADING
        : PROFILE_UPDATE_CONFIRM_HEADING,
    subHeading:
      isNameChanged &&
      userProfile.data.level !== 1 &&
      userProfile.data.level !== 2
        ? NAME_CHANGE_WARNING_SUBHEADING
        : PROFILE_UPDATE_CONFIRM_SUBHEADING,
  };
};
