import {
  KycInProgressIcon,
  KycRejectedIcon,
  KycVerifiedIcon,
} from "../../../icons";
const getKYCStatusKey = (level) => {
  switch (level) {
    case 0:
      return "rejected";
    case 1:
      return "notVerified";
    case 2:
      return "inProgress";
    case 3:
      return "verified";
    default:
      return "";
  }
};

const kycIcons = {
  inProgress: <KycInProgressIcon className="kyc-status-sidebar" />,
  notVerified: <KycRejectedIcon className="kyc-status-sidebar" />,
  rejected: <KycRejectedIcon className="kyc-status-sidebar" />,
  verified: <KycVerifiedIcon className="kyc-status-sidebar" />,
};

const getKYCBadgeText = (level) => {
  switch (level) {
    case 0:
      return {
        text: <span>Rejected</span>,
        className: "kyc-danger",
        id: "kyc-danger",
      };
    case 1:
      return {
        text: (
          <>
            <span className="d-inline-block me-1">Not</span>
            <span>Verified</span>
          </>
        ),
        className: "kyc-danger",
        id: "kyc-danger",
      };
    case 2:
      return {
        text: <span>Pending</span>,
        className: "kyc-warning",
        id: "kyc-warning",
      };
    case 3:
      return {
        text: <span>Verified</span>,
        className: "kyc-primary",
        id: "kyc-primary",
      };
    default:
      return "";
  }
};

export const getKycIcon = (level = "") => {
  const status = getKYCStatusKey(level);
  if (status) {
    return (
      <div className="d-flex justify-content-start align-items-center">
        {kycIcons[status]}

        <span className="nav-text">
          <span
            id={`${getKYCBadgeText(level)?.id || ""}`}
            className={`${getKYCBadgeText(level)?.className || ""} w-fit d-flex fs-6 fw-semibold`}
          >
            {getKYCBadgeText(level)?.text || ""}
          </span>
        </span>
      </div>
    );
  } else return "";
};

export const getChatBtn = (open = () => {}, count = 0, text = "Let's Chat") => {
  return (
    <div
      className="chat-btn-cont d-flex justify-content-start align-items-center"
      onClick={open}
    >
      <button className={`chat-button ${count > 0 && "active"}`}>
        <i className="bi bi-chat-dots-fill fs-3 fw-bold"></i>
        {count > 0 && <span className="unread-msg badge-danger">{count}</span>}
      </button>

      <span className="nav-text">
        <span className="w-fit d-flex fs-6 fw-semibold">{text}</span>
      </span>
    </div>
  );
};

export const getKycStatus = (level) => {
  switch (level) {
    case 0:
      return "Rejected";
    case 1:
      return "Not Verified";
    case 2:
      return "Pending";
    case 3:
      return "Verified";
    default:
      return "";
  }
};

export const authenticateUserToZendesk = async (
  token = "",
  setIsAuthenticate
) => {
  if (window.zE && token)
    window.zE(
      "messenger",
      "loginUser",
      function jwtCallback(callback) {
        callback(token);
      },
      function loginCallback(error) {
        if (error) {
          // Example error handling
          const { type, reason, message } = error;
          console.log("type, reason, message>>", type, reason, message);
        } else {
          setIsAuthenticate(true);
        }
      }
    );
};
