import React, { useMemo, useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import ZendeskWidget from "./ZendeskWidget";
import PulseLoader from "react-spinners/PulseLoader";

const ZendeskComp = () => {
  const { data: userProfile } = useSelector((state) => state.userProfile);
  const [isZenLoad, setIsZenLoad] = useState(true);

  useLayoutEffect(() => {
    if (userProfile?.zendeskToken && isZenLoad) {
      setTimeout(() => {
        setIsZenLoad(false);
      }, 900);
    }
  }, [userProfile?.zendeskToken, isZenLoad]);

  const memoizeduseMemo = useMemo(
    () =>
      !isZenLoad ? (
        <ZendeskWidget zendeskToken={userProfile?.zendeskToken} />
      ) : (
        <span className="d-flex align-items-center justify-content-center">
          <PulseLoader color={"#96a0af"} size={9} speedMultiplier={0.5} />
        </span>
      ),

    [isZenLoad, userProfile?.zendeskToken]
  );

  return <>{memoizeduseMemo}</>;
};

export default ZendeskComp;
