import copy from "copy-to-clipboard";
import { error } from "../components/shared/Alert";
import moment from "moment";
import {
  BADGE_TYPES,
  GENERIC_CONSTANTS,
  AdminRoutes,
  ROLES,
  CRYPTO_TRANSACTION_LINK,
  CRYPTO_WALLET_ADDRESS_LINK,
} from "./Constants";
import { KYC_STATUS } from "../components/admin/Users/constants";
import { getLocalStorageItem } from "../config/AuthSetting";
import { Link } from "react-router-dom";
import ToolTip from "../components/shared/Tooltip";
import { isObject } from "lodash";

export const copyToClipboard = async (value) => {
  try {
    await copy(value);
  } catch (err) {
    error("Cannot copy");
  }
};

function isScientificNotation(value) {
  // Define a regular expression to match scientific notation
  // The pattern matches a decimal coefficient followed by an 'e' or 'E' and an optional sign and exponent
  const scientificNotationPattern = /^[+-]?(\d+(\.\d*)?|\.\d+)([eE][+-]?\d+)$/;

  // Use the test() method to check if the input string matches the pattern
  return scientificNotationPattern.test(value);
}

const ToFixed = (x) => {
  if (Math.abs(x) < 1.0) {
    let e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
};

function hasMoreThanEightDecimalPlaces(number) {
  // Convert the number to a string
  const numberString = number.toString();
  // Check if there is a decimal point
  if (numberString.includes(".")) {
    // Split the string into integer and decimal parts
    const [integerPart, decimalPart] = numberString.split(".");
    // Check if the length of the decimal part is greater than 8
    if (decimalPart.length > 8) {
      return true;
    }
  }
  return false;
}

function hasMoreThanTwoDecimalPlaces(number) {
  // Convert the number to a string
  const numberString = number.toString();
  // Check if there is a decimal point
  if (numberString.includes(".")) {
    // Split the string into integer and decimal parts
    const [integerPart, decimalPart] = numberString.split(".");
    // Check if the length of the decimal part is greater than 8
    if (decimalPart.length > 2) {
      return true;
    }
  }
  return false;
}

function truncateDecimals(number, decimalPlaces) {
  // Convert the number to a string
  const numberString = number.toString();
  // Find the position of the decimal point
  const decimalIndex = numberString.indexOf(".");
  if (decimalIndex !== -1) {
    const truncatedString = numberString.slice(
      0,
      decimalIndex + decimalPlaces + 1
    );
    return truncatedString;
  } else {
    // If the number is an integer, no need for truncation
    return number;
  }
}

export const fixNumberUpTo8 = (val = 0) => {
  val = parseFloat(val);
  let isScientific = isScientificNotation(val);
  if (isScientific) {
    val = ToFixed(val);
  }
  let isTrue = hasMoreThanEightDecimalPlaces(val);
  if (isTrue) {
    // val = Math.trunc(parseFloat(val) * 1e8) / 1e8;
    val = truncateDecimals(val, 8);
  }
  isScientific = isScientificNotation(val);
  if (isScientific) {
    val = ToFixed(val);
  }
  return val;
};
export const FormatEightDecimals = (val = 0) => {
  val = parseFloat(val);
  let isScientific = isScientificNotation(val);
  if (isScientific) {
    val = ToFixed(val);
  }
  let isTrue = hasMoreThanEightDecimalPlaces(val);
  if (isTrue) {
    // val = Math.trunc(parseFloat(val) * 1e8) / 1e8;
    val = truncateDecimals(val, 8);
  }
  isScientific = isScientificNotation(val);
  if (isScientific) {
    val = ToFixed(val);
  }
  return val;
};

export const FormatTwoDecimals = (val = 0) => {
  val = parseFloat(val);
  let isScientific = isScientificNotation(val);
  if (isScientific) {
    val = ToFixed(val);
  }
  let isTrue = hasMoreThanTwoDecimalPlaces(val);
  if (isTrue) {
    val = truncateDecimals(val, 2);
  }
  isScientific = isScientificNotation(val);
  if (isScientific) {
    val = ToFixed(val);
  }
  return val;
};

var formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

export const CurrencyFormattor = (value) => {
  return formatter.format(value);
};

export const formatDate = (date) => {
  return date ? moment(date).format(GENERIC_CONSTANTS.DATE_TIME_FORMAT) : "";
};

export const formatDateTime = (date) => {
  return date ? moment(date).format(GENERIC_CONSTANTS.TIME_DATE_FORMAT) : "";
};

export const formatDateOnly = (date) => {
  return date ? moment(date).format(GENERIC_CONSTANTS.DATE_FORMAT) : "";
};

export const formatDateFromNow = (date) => {
  return date ? moment(date).fromNow() : "";
};

export const capitalizeText = (val) => {
  return val ? val.charAt(0).toUpperCase() + val.slice(1) : "";
};

export const txIdUrl = (path, currency) => {
  if (path.split("/").length > 1) return path;
  else {
    currency = path.slice(0, 2) === "0x" ? "eth" : currency;
    return (
      (CRYPTO_TRANSACTION_LINK[currency] &&
        `${CRYPTO_TRANSACTION_LINK[currency]}/${path}`) ||
      ""
    );
  }
};

export const walletAddressUrl = (path, currency) => {
  if (path.split("/").length > 1) return path;
  else {
    currency = path.slice(0, 2) === "0x" ? "eth" : currency;
    return (
      (CRYPTO_WALLET_ADDRESS_LINK[currency] &&
        `${CRYPTO_WALLET_ADDRESS_LINK[currency]}/${path}`) ||
      ""
    );
  }
};

export const getKycStatus = (level) => {
  return {
    badge:
      level === 1
        ? BADGE_TYPES.INFO
        : level === 2
          ? BADGE_TYPES.WARNING
          : level === 3
            ? BADGE_TYPES.SUCCESS
            : BADGE_TYPES.DANGER,
    title:
      level === 1
        ? KYC_STATUS.NOT_VERIFIED
        : level === 2
          ? KYC_STATUS.IN_PROGRESS
          : level === 3
            ? KYC_STATUS.APPROVED
            : KYC_STATUS.REJECTED,
  };
};

export const BSBRegex = (e) => {
  let value = e;
  if (value.charAt(3) === "-") {
    value = value.replace(/\D/g, "");
    value = value.substring(0, 3) + "-" + value.substring(3);
  } else {
    value = value.replace(/\D/g, "");
  }

  // Add hyphen after the 3rd character if it's not already there
  if (value.length > 3 && value.charAt(3) !== "-") {
    value = value.substring(0, 3) + "-" + value.substring(3);
  }

  return value;
};

//Roles

export const roleAction = {
  USER_DEPOSIT_THRESHOLD_LIMIT: "UserDepositThresholdLimit",
  ADMIN_ACTIONS: "adminActions",
  COIN_ADD_NEW: "coinAddNew",
  COIN_ENABLE_DISABLE: "coinEnableDisable",
  PENDING_DEPOSIT_UPDATE_CONTACT_EMAIL: "pendingDepositUpdateContactEmail",
  WITHDRAW_APPROVE_REJECT: "withdrawApproveReject",
  BUY_ASSET_APPROVE_REJECT: "buyAssetApproveReject",
  WITHDRAW_ASSET_APPROVE_REJECT: "withdrawAssetApproveReject",
  ORGANIZATION_CREATE: "organizationCreate",
  ORGANIZATION_UPDATE: "organizationUpdate",
};

export const rolePermissionRestricted = {
  super: [],
  supervisor: [
    roleAction.USER_DEPOSIT_THRESHOLD_LIMIT,
    roleAction.PENDING_DEPOSIT_UPDATE_CONTACT_EMAIL,
    roleAction.WITHDRAW_APPROVE_REJECT,
    roleAction.BUY_ASSET_APPROVE_REJECT,
    roleAction.WITHDRAW_ASSET_APPROVE_REJECT,
  ],
  admin: [
    roleAction.USER_DEPOSIT_THRESHOLD_LIMIT,
    roleAction.ADMIN_ACTIONS,
    roleAction.COIN_ADD_NEW,
    roleAction.COIN_ENABLE_DISABLE,
    roleAction.PENDING_DEPOSIT_UPDATE_CONTACT_EMAIL,
    roleAction.WITHDRAW_APPROVE_REJECT,
    roleAction.BUY_ASSET_APPROVE_REJECT,
    roleAction.WITHDRAW_ASSET_APPROVE_REJECT,
    roleAction.ORGANIZATION_CREATE,
    roleAction.ORGANIZATION_UPDATE,
  ],
};

export const isAuthorizedAction = (action = "", permissions = []) => {
  if (!action) return false;
  const role = getLocalStorageItem("userInfo")?.role;
  if (role === ROLES.SUPER) return true;
  else return permissions && permissions.includes(action);
};

export const isAuthorized = (action = "") => {
  const role = getLocalStorageItem("userInfo")?.role;
  return !rolePermissionRestricted[role].includes(action);
};

export const redirectName = (name = "", role = ROLES.USER, id = "") => {
  return (
    <span>
      <ToolTip title={name} placement="left">
        <Link
          to={
            role === ROLES.USER
              ? id
                ? `${AdminRoutes.USERS}?userId=${id}`
                : AdminRoutes.USERS
              : AdminRoutes.ADMINS
          }
        >
          {name}
        </Link>
      </ToolTip>
    </span>
  );
};

export const removeSpace = (str = "") => {
  return str.replace(/\s+/g, "");
};

export const formatDateRangeOnly = (date) => {
  return date ? moment(date).format(GENERIC_CONSTANTS.DATE_RANGE_FORMAT) : "";
};

export const getFilterQuery = (filter = null) =>
  filter && isObject(filter)
    ? Object.keys(filter).reduce((prev, key) => {
        let value = filter[key];
        if (key === "organisationIds" && Array.isArray(value)) {
          value = value.map(encodeURIComponent).join(",");
        } else if (value !== null && typeof value !== "string") {
          value = encodeURIComponent(value.toString());
        }
        return value ? `${prev}&${key}=${value}` : prev;
      }, "")
    : "";
