import React, { useLayoutEffect, useState } from "react";
import { authenticateUserToZendesk, getChatBtn } from "./helper";
import { useZendesk } from "react-use-zendesk";

const ZendeskWidget = ({ zendeskToken }) => {
  const { open, unreadMessages } = useZendesk();
  const [isAuthenticate, setIsAuthenticate] = useState(false);

  useLayoutEffect(() => {
    if (window.zE && zendeskToken && !isAuthenticate) {
      // user login to zendesk
      authenticateUserToZendesk(zendeskToken, setIsAuthenticate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.zE, zendeskToken, isAuthenticate]);

  return <>{open ? getChatBtn(open, unreadMessages) : null}</>;
};

export default ZendeskWidget;
